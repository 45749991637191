import axios from "axios";
import Emitter from "./emitter";
import store from "../redux/store";
import { userSlice } from "../redux/slices/user.slice";

let activeRequests = 0;

const request = async (type, url, payload = null, isBackground = false) => {
  if (!isBackground) {
    Emitter.emit("AJAX_SEND");
  }

  const token = store.getState()?.currentUser?.tokens?.accessToken;

  const result = {
    data: {},
    error: null,
    status: null,
    statusText: null,
    // code: null,
  };
  try {
    activeRequests += 1;
    const response = await axios({
      method: type,
      url,
      
      baseURL: `${process.env.REACT_APP_API_SERVER}`,
      // baseURL: `https://portal-svc--pharmacy-b-7j5ay3qlhq-uc.a.run.app`,
      // baseURL: `http://localhost:8002`,
      data: payload,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    result.data = response.data?.data?.data;
    result.message = response.data?.data?.message;
    result.status = response.status;
    result.statusText = response.statusText;
    result.responseData = response?.data

  } catch (error) {
    console.log("error", error);
    const status = error?.response?.status;
    const httpStatus = error?.response?.data?.errors?.httpStatus;

    const statusText = error.response?.statusText;
    result.error = error.response?.data?.message?.error;
    result.message = error.response?.data?.message?.error?.message;
    result.status = status;

    if (status === 0) {
      //Not connected
    } else if (status === 401 || httpStatus === 401) {
      //Unauthorized
      store.dispatch(userSlice.actions.logout());
    } else if (status === 404) {
      //not found
    } else if (status === 500) {
      //Server Error
    } else if (statusText === "parsererror") {
      //JSON parse failed
    } else if (statusText === "timeout") {
      //Time out
    } else if (statusText === "abort") {
      //Ajax request aborted
    } else if (status === 403) {
      //Forbidden (No Permission)
    } else {
      //Uncaught Error
    }
  }
  activeRequests -= 1;
  if (activeRequests === 0) {
    Emitter.emit("AJAX_COMPLETE", result);
  }
  if (result.message !== undefined && result.message !== "success") {
    Emitter.emit("SHOW_NOTIFICATION", {
      message: result.message,
      isError: result.error !== null,
    });
  }

  if (result.error?.code === "ERR_GUEST_MODE_INSUFFICIENT_PERMISSION") {
    Emitter.emit("SHOW_GUEST_REGISTERATION_MODAL");
  } else if (result.error?.code === "ERR_PHARMACYID_NOT_PROVIDED") {
    Emitter.emit("REDIRECT_TO_CHOOSE_PHARMACY");
  }

  return result;
};

const getBackgroundRequest = async (url) => {
  return await request("get", url, null, true);
};

const getRequest = async (url, isBackground = false) => {
  return await request("get", url, null, isBackground);
};

const postRequest = async (url, payload) => {
  return await request("post", url, payload);
};

const putRequest = async (url, payload) => {
  return await request("put", url, payload);
};

const patchRequest = async (url, payload) => {
  return await request("patch", url, payload);
};

const deleteRequest = async (url, payload) => {
  return await request("delete", url, payload);
};

const http = {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  patch: patchRequest,
  delete: deleteRequest,
  getBackgroundRequest: getBackgroundRequest,
};

export default http;
