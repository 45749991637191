import React from "react";
import Modal from "react-bootstrap/Modal";
import TransferInRequestService from "../../services/transfer-in-request.service";

const AcceptTransferRequestModal = (props) => {
  const handleProceed = async () => {
    const result = await TransferInRequestService.Accept({
      transferRequestId: props?.request?.RequestId,
      trxTraceId: props.request?.trxTraceId,
    });
    if (!result.error) {
      props.onHide(true);
    }
  };
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="accept-transfer-request-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="accept-transfer-request-modal-title">
          Accept Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to ACCEPT request #`
        <b>{props?.request?.RequestId}</b>`?
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          onClick={handleProceed}
        >
          Proceed
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptTransferRequestModal;
