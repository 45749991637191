import React, { useEffect, useState } from "react";
import moment from "moment";
import { debounce } from "lodash";
import EditTransferRequestModal from "../EditTransferRequestModal";
import ViewTransferRequestModal from "../ViewTransferRequestModal";
import ViewPatientModal from "../ViewPatientModal";

import ViewPharmacyBranchModal from "../ViewPharmacyBranchModal";
import TransferInRequestService from "../../services/transfer-in-request.service";
import TransferOutRequestService from "../../services/transfer-out-request.service";
import PharmacyBranchService from "../../services/pharmacy-branch.service";
import PatientService from "../../services/patient.service";
import AcceptTransferRequestModal from "../AcceptTransferRequestModal";
import RejectTransferRequestModal from "../RejectTransferRequestModal";
import CancelTransferRequestModal from "../CancelTransferRequestModal";
import ManualAuctoComplateRequestModal from "../ManualAuctoComplateRequestModal";

import FinishReviewTransferRequestModal from "../FinishReviewTransferRequestModal";
import Emitter from "../../utility/emitter";
import helpers from "../../utility/helpers";
import "./index.scss";
import http from "../../utility/http";
import Swal from "sweetalert2";
import { Pagination } from "../Pagination";
import { useSelector } from "react-redux";
import { firstDoseState } from "../../atoms/firstDoes";
import { useRecoilState } from "recoil";

let interval = null;
const TransferRequestDataGrid = (props) => {
  const [firstDose, setFirstDose] = useRecoilState(firstDoseState);
  console.log("firstDose", firstDose);
  const currentUser = useSelector((state) => state.currentUser);
  const deleteById = async (record) => {
    if (props.requesttype === 1) {
      await http.delete(
        `/v1/transfer/transfer-out-request/${record?.RequestId}`,
        {}
      );
    } else {
      await http.delete(
        `/v1/transfer/transfer-in-request/${record?.RequestId}`,
        {}
      );
    }
    await getAll("", 1, 50);
  };
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const debouncedSearch = debounce((keyword) => {
    setSearchText(keyword);
    getAll(keyword);
  }, 300);

  //const records = useSelector((state) => (props.requesttype===1 ? state.transferOutRequests : state.transferInRequests))
  const [searchText, setSearchText] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [acceptTransferRequestModalShow, setAcceptTransferRequestModalShow] =
    useState(false);
  const [
    finishReviewTransferRequestModalShow,
    setFinishReviewTransferRequestModalShow,
  ] = useState(false);
  const [rejectTransferRequestModalShow, setRejectTransferRequestModalShow] =
    useState(false);
  const [cancelTransferRequestModalShow, setCancelTransferRequestModalShow] =
    useState(false);
  const [
    manualAutoCompleteRequestModalShow,
    setManualAutoCompleteRequestModalShow,
  ] = useState(false);

  const [selectedRowIndex, setSelectedRowIndex] = useState({});
  const [request, setRequest] = useState({});
  const [pharmacyBranch, setPharmacyBranch] = useState({});
  const [patient, setPatient] = useState({});

  const [records, setRecords] = useState([]);
  const [transferRequestModalShow, setTransferRequestModalShow] =
    useState(false);
  const [viewTransferRequestModalShow, setViewTransferRequestModalShow] =
    useState(false);

  const [viewPharmacyBranchModalShow, setViewPharmacyBranchModalShow] =
    useState(false);
  const [viewPatientModalShow, setViewPatientModalShow] = useState(false);

  const getStatusIcon = (statusId) => {
    switch (statusId) {
      case 1:
      case 2:
        return "warning";
      case 3:
        return "success";
      case 4:
      case 5:
        return "danger";
      default:
        return "";
    }
  };

  const [total, setTotal] = useState(0);
  const getAll = async (keyword, page, limit, sort = null) => {
    const result = await (props.requesttype === 1
      ? TransferOutRequestService
      : TransferInRequestService
    ).GetAll(keyword, page, limit, sort);
    if (result?.error === null) {
      setRecords(result?.data?.data);
      setTotal(result?.data?.total);
    }
  };
  const getDetailedById = async (id) => {
    const result = await (props.requesttype === 1
      ? TransferOutRequestService
      : TransferInRequestService
    ).GetById(id);
    if (result?.error === null) {
      setRequest(result?.data);

      /*setRecords(
        records?.map((record) => {
          if (record.RequestId === id) {
            record.IsRead = 1;
          }
          return record;
        })
      );*/

      Emitter.emit("update-notifications");
    }
  };

  const getDetailedPharmacyBranchById = async (id) => {
    const result = await PharmacyBranchService.GetById(id);
    if (result?.error === null) {
      setPharmacyBranch(result?.data[0][0]);
    }
  };

  const getDetailedPatientById = async (id) => {
    const result = await PatientService.GetById(id);
    if (result?.error === null) {
      setPatient(result?.data);
    }
  };

  const handleRefresh = (id) => {
    if (id !== undefined) {
      getDetailedById(id);
    }
  };

  useEffect(() => {
    Emitter.on("update-transfer-request-list", () => {
      getAll(searchText, page, limit);
    });

    Emitter.emit("update-transfer-request-list");
    return () => {
      Emitter.off("update-transfer-request-list");
    };
  }, [page, limit]);
  // useEffect(() => {
  //   if (!interval) {
  //     interval = setInterval(() => {
  //       getAll("", 1, 50);
  //     }, 10000);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  console.log("currentUser?.user?.roleId", currentUser?.user?.roleId);
  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          {(currentUser?.user?.roleId === 1 ||
            currentUser?.user?.roleId === 2 ||
            currentUser?.user?.roleId === 3) && (
              <button
                className="btn btn-default"
                type="button"
                onClick={() => {
                  setRequest(null);
                  setEditMode(true);
                  setTransferRequestModalShow(true);
                }}
                test-id="send-or-request-transfer"
              >
                {props.requesttype === 1 ? (
                  <>
                    <i className="fa-solid fa-arrow-up me-2"></i>
                    <span>Send A Transfer</span>
                  </>
                ) : (
                  <>
                    <i className="fa-solid fa-arrow-down me-2"></i>
                    <span>Request A Transfer</span>
                  </>
                )}
              </button>
            )}

          <div className="search-box">
            <i className="fa-solid fa-magnifying-glass"></i>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search…"
                spellCheck="false"
                data-ms-editor="true"
                onChange={(event) => debouncedSearch(event.target.value)}
              />
            </form>
          </div>
        </div>

        <div className="card-body datagrid p-0">
          <table className="table mb-0" test-id="data-table">
            <thead>
              <tr>
                <th
                  className="text-center"
                  onClick={() => {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    getAll("", 1, 50, {
                      sortOrder: sortOrder === "asc" ? "desc" : "asc",
                      sortBy: "request",
                    });
                  }}
                >
                  Request# <i className="fa-solid fa-sort"></i>
                </th>
                <th
                  className="text-center"
                  onClick={() => {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    getAll("", 1, 50, {
                      sortOrder: sortOrder === "asc" ? "desc" : "asc",
                      sortBy: "date",
                    });
                  }}
                >
                  Request Date <i className="fa-solid fa-sort"></i>
                </th>
                <th className="text-center">Patient</th>
                <th className="text-center">Rx Number</th>

                <th className="text-center">Pharmacy</th>
                <th
                  className="text-center"
                  onClick={() => {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    getAll("", 1, 50, {
                      sortOrder: sortOrder === "asc" ? "desc" : "asc",
                      sortBy: "status",
                    });
                  }}
                >
                  Status <i className="fa-solid fa-sort"></i>
                </th>

                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((record, index) => {
                const inFirstDose = firstDose.inFirstDose(
                  record?.FromPharmacyId,
                  firstDose.pharmacies
                );
                return (
                  <tr
                    key={index}
                    className={[
                      index === selectedRowIndex
                        ? "selected"
                        : record?.IsRead === 0
                          ? "new"
                          : "",
                      // inFirstDose ? "inFirstDose" : "",
                    ].join(" ")}
                  >
                    <td className="text-center">
                      {record?.RequestId}
                      {inFirstDose && (
                        <span className="badge bg-success ms-2">
                          First Dose
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      {moment(record?.RequestDate).format("MMMM DD YYYY")}
                    </td>
                    <td>
                      <a
                        className="btn btn-link"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedPatientById(record?.PatientId);
                          setViewPatientModalShow(true);
                        }}
                      >
                        {record?.PatientName}
                      </a>
                    </td>
                    <td className="text-center">{record?.RxNumber}</td>
                    <td>
                      <a
                        className="btn btn-link"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedPharmacyBranchById(
                            props.requesttype === 1
                              ? record?.ToPharmacyBranchId
                              : record?.FromPharmacyBranchId
                          );
                          setViewPharmacyBranchModalShow(true);
                        }}
                      >
                        {props.requesttype === 1
                          ? `${record?.ToPharmacyBranchName
                          } ${helpers.getPharmacyFormattedAddress(
                            record?.ToPharmacyBranchAddress,
                            record?.ToPharmacyBranchCityName,
                            record?.ToPharmacyBranchStateName,
                            true
                          )}`
                          : `${record?.FromPharmacyBranchName
                          } ${helpers.getPharmacyFormattedAddress(
                            record?.FromPharmacyBranchAddress,
                            record?.FromPharmacyBranchCityName,
                            record?.FromPharmacyBranchStateName,
                            true
                          )}`}
                      </a>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <i
                          className={`fa-solid fa-circle text-${getStatusIcon(
                            record?.StatusId
                          )} me-1`}
                        ></i>
                        <div>{record?.StatusName}</div>
                        <div></div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <a
                          href="#"
                          className={`view${!record?.actions?.view ? " disabled" : ""
                            }`}
                          title="View"
                          data-toggle="tooltip"
                          onClick={async () => {
                            record.IsRead = 1;
                            setSelectedRowIndex(index);
                            await getDetailedById(record?.RequestId);
                            setEditMode(false);
                            setViewTransferRequestModalShow(true);
                          }}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </a>
                        {(currentUser?.user?.roleId === 1 ||
                          currentUser?.user?.roleId === 2 ||
                          currentUser?.user?.roleId === 3) && (
                            <>
                              <a
                                href="#"
                                className={`edit${!record?.actions?.edit ? " disabled" : ""
                                  }`}
                                title="Edit"
                                data-toggle="tooltip"
                                onClick={async () => {
                                  record.IsRead = 1;
                                  setSelectedRowIndex(index);
                                  await getDetailedById(record?.RequestId);
                                  setEditMode(true);
                                  setTransferRequestModalShow(true);
                                }}
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </a>
                              <a
                                href="#"
                                title="Delete"
                                data-toggle="tooltip"
                                onClick={async () => {
                                  // await deleteById(record);
                                  const result = await Swal.fire({
                                    title: "",
                                    text: `Are you sure you want to delete this? ?`,
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#6c757d",
                                    confirmButtonText: "Yes",
                                  });
                                  if (result.isConfirmed) {
                                    await deleteById(record);
                                  }
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </a>
                            </>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination setPage={setPage} page={page} total={total} limit={limit} />
        {/* <div className="card-footer d-flex justify-content-between align-items-center bg-white">
          <div className="hint-text">
            Showing <b>{(page - 1) * limit + 1}</b> to{" "}
            <b>{Math.min(page * limit, total)}</b> of <b>{total}</b> entries
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination mb-0">
              <li className="page-item ">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {Array(Math.ceil(total / limit))
                .fill()
                .map((_, i) => (
                  <li className={`page-item ${i + 1 === page ? "active" : ""}`}>
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(i + 1);
                      }}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page < Math.ceil(total / limit)) {
                      setPage(page + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>

      <EditTransferRequestModal
        cb={getAll}
        show={transferRequestModalShow}
        onHide={(refresh, transferRequestId) => {
          setTransferRequestModalShow(false);
          getAll("", 1, 50);
          if (refresh === true) {
            handleRefresh(transferRequestId);
          }
        }}
        requesttype={props.requesttype}
        request={request}
        handleAcceptTransferRequestModalShow={(state) =>
          setAcceptTransferRequestModalShow(state)
        }
        handleFinishReviewTransferRequestModalShow={(state) =>
          setFinishReviewTransferRequestModalShow(state)
        }
        handleRejectTransferRequestModalShow={(state) =>
          setRejectTransferRequestModalShow(state)
        }
        handleCancelTransferRequestModalShow={(state) =>
          setCancelTransferRequestModalShow(state)
        }
      />

      <ViewTransferRequestModal
        show={viewTransferRequestModalShow}
        onHide={() => {
          getAll("", 1, 50);
          setViewTransferRequestModalShow(false);
        }}
        requesttype={props.requesttype}
        request={request}
        handleEditTransferRequestModalShow={(state) =>
          setTransferRequestModalShow(state)
        }
        handleAcceptTransferRequestModalShow={(state) =>
          setAcceptTransferRequestModalShow(state)
        }
        handleFinishReviewTransferRequestModalShow={(state) =>
          setFinishReviewTransferRequestModalShow(state)
        }
        handleRejectTransferRequestModalShow={(state) =>
          setRejectTransferRequestModalShow(state)
        }
        handleCancelTransferRequestModalShow={(state) =>
          setCancelTransferRequestModalShow(state)
        }
        handleManualAutoCompleteRequestModalShow={(state) =>
          setManualAutoCompleteRequestModalShow(state)
        }
      />

      <AcceptTransferRequestModal
        show={acceptTransferRequestModalShow}
        onHide={(refresh) => {
          setAcceptTransferRequestModalShow(false);
          getAll("", 1, 50);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : request.RequestId);
          }
        }}
        requesttype={props.requesttype}
        request={request}
      />

      <FinishReviewTransferRequestModal
        show={finishReviewTransferRequestModalShow}
        onHide={(refresh) => {
          setFinishReviewTransferRequestModalShow(false);
          getAll("", 1, 50);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : request.RequestId);
          }
        }}
        requesttype={props.requesttype}
        request={request}
      />

      <RejectTransferRequestModal
        show={rejectTransferRequestModalShow}
        onHide={(refresh) => {
          setRejectTransferRequestModalShow(false);
          getAll("", 1, 50);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : request.RequestId);
          }
        }}
        requesttype={props.requesttype}
        request={request}
      />
      <CancelTransferRequestModal
        show={cancelTransferRequestModalShow}
        onHide={(refresh) => {
          setCancelTransferRequestModalShow(false);
          getAll("", 1, 50);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : request.RequestId);
          }
        }}
        request={request}
      />

      <ManualAuctoComplateRequestModal
        show={manualAutoCompleteRequestModalShow}
        onHide={(refresh) => {
          setManualAutoCompleteRequestModalShow(false);
          getAll("", 1, 50);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : request.RequestId);
          }
        }}
        request={request}
      />

      <ViewPharmacyBranchModal
        show={viewPharmacyBranchModalShow}
        onHide={() => setViewPharmacyBranchModalShow(false)}
        data={pharmacyBranch}
      />

      <ViewPatientModal
        show={viewPatientModalShow}
        onHide={() => setViewPatientModalShow(false)}
        data={patient}
      />
    </>
  );
};

export default TransferRequestDataGrid;
